import React from "react"

export default function DaZeroAlBrand() {
  return (
    <section className="section-dazeroalbrand">
      <div className="container">
        <div className="columns is-centered px-5">
          <div className="column is-four-fifths">
            <h1>RETRO<br />MARKETING</h1>
            <h3>Il Marketing strategico tra nostalgia e innovazione</h3>
            <p>Sempre più spesso le aziende, nella progettazione e nella comunicazione dei propri prodotti, fanno leva sul passato e sulla nostalgia. Pensiamo, per esempio, al settore alimentare (le cose fatte “come una volta”), all’automotive (i nuovi modelli ispirati a celebri vetture del passato), al gaming (i personaggi ripresi dai vecchi videogiochi anni Ottanta).</p>
            <p>Questo avviene perché il passato dà un’idea di sicurezza e di appartenenza a un retroterra comune, un fattore di attrazione di grande importanza in anni come quelli che stiamo vivendo, in cui molte certezze sono venute meno (pandemia, guerra, crisi climatica). Questo volume vuole offrire ad aziende, consulenti e agenzie di marketing una guida pratica per realizzare progetti di branding e comunicazione che sfruttino la leva della nostalgia e del “vintage”, per aumentare il coinvolgimento del proprio target.</p>
            <p>Grazie anche a numerosi casi di studio italiani e internazionali, mostra inoltre come unire i richiami al passato con elementi di innovazione, per catturare l’attenzione anche dei più giovani e offrire ai clienti esperienze sempre nuove.</p>
          </div>
        </div>
      </div>
    </section>
  )
}
